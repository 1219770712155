$primary-color: rgb(243, 243, 243);
$primary-font-color: black;

$secondary-color: rgb(187, 100, 94);
$secondary-font-color: rgba(255, 254, 249, 1);

$overstock-garden-bg: rgb(0, 81, 59);
$overstock-garden-color: white;
$exterioo-tuinmeubelen-bg: rgb(54, 159, 76);
$exterioo-tuinmeubelen-color: white;
$overstock-home-bg: #404445;
$overstock-home-color: white;
