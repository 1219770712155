.blv-about-us-section {
  margin: $xtra-large-padding auto $small-padding auto;
  + section {
    padding-top: 48px;
  }
  .blv-about-us-text {
    margin: $small-padding auto;
    width: 100%;
    max-width: $big-breakpoint-container-max-width;
    padding: $small-padding;

    .blv-title-text {
      h2 {
        font-weight: bold;
        font-size: $xtra-large-font-size;
      }
    }
  }

  .blv-intro-images-banner {
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: space-evenly;
  }

  .blv-aesthetic-img-wrapper {
    width: calc(50% - 5px * 3);
    height: calc(50% - 5px * 3);

    &.blv-right-aesthetic-img-wrapper {
      transform: translateY(33%);
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: calc(33% - 10px);
        height: calc(33% - 10px);
        background-color: $primary-color;
        transform: translateY(calc(-100% - 10px));
      }
    }
  }

  figure {
    height: 100%;
    margin-bottom: 0;

    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }
}

@include media-breakpoint-up(sm) {
  .blv-about-us-section {
    margin: $xtra-xtra-large-padding auto;

    .blv-intro-images-banner {
      margin: $xtra-xtra-large-padding auto;
    }

    + section {
      padding-top: 80px;
    }

    .blv-title-text {
      h2 {
        font-weight: bold;
        font-size: $xtra-large-font-size;
        text-align: right;
      }
    }
  }
}
