.blv-hero-section {
  position: relative;
  height: 500px;

  figure {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-position: top;
      object-fit: cover;
    }
  }

  .blv-hero-text {
    text-shadow: 0 1px 11px black;
    margin: $small-padding;
    min-width: 310px;

    h1 {
      font-size: $xtra-xtra-large-font-size;
      font-weight: bold;
      letter-spacing: 2px;
    }

    color: $secondary-font-color;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    max-width: 310px;
    transform: translateY(-50%);
  }
}


@include media-breakpoint-up(sm) {
  .blv-hero-section {
    height: 500px;

    .blv-hero-text {
      left: 20%;
    }
  }
}

@include media-breakpoint-up(lg) {
  .blv-hero-section {
    height: 550px;
  }
}


