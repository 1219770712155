.blv-footer-section {
  position: relative;
  color: $secondary-font-color;

  &:not(.blv-side-menu-footer) {
    .blv-connect-footer-section {
      text-align: right;
    }
  }

  &.blv-side-menu-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  a {
    color: $secondary-font-color;
    text-decoration: none;
  }


  footer {
    padding: $large-padding;

    .blv-beliving-logo {
      height: 35px;
      width: 75px;
      margin: $small-padding 0;
    }
  }

  .blv-full-width-bg {
    position: absolute;
    background-color: darken($secondary-color, 5);

    &.side-menu-footer {
      background-color: black;
    }

    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  small {
    font-size: 0.7em;
  }

  a {
    color: $secondary-font-color;

    &:hover {
      color: darken($secondary-font-color, 10);
    }
  }

  .blv-side-menu-logo-wrapper {
    position: absolute;
    right: $large-padding;
    bottom: $large-padding;
    width: auto;
  }

  //TODO: this is pretty hacky, better way would be to override this bootstrap grid variables.
  .container, .container-sm {
    @media (min-width: 576px) and (max-width: 768px) {
      max-width: 720px;
    }
  }

}
