.blv-join-us-section {
  position: relative;
  height: 375px;
  background-color: $primary-color !important;

  & + section {
    padding-top: 145px;
  }

  figure {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-position: top;
      object-fit: cover;
    }
  }

  .blv-join-us-text-wrapper {
    z-index: 1;
    padding: $medium-padding;

    transform: translateY(50%);

    min-width: 330px;
    color: $secondary-font-color;
    position: absolute;

    left: 0;
    bottom: 0;
    width: 50%;
    max-width: 650px;
    height: 300px;
    background-color: $secondary-color;
    overflow: hidden;

    .blv-join-us-text {
      width: 100%;
      max-width: 400px;
      padding: $xtra-xtra-small-padding;

      h2 {
        font-size: $xtra-large-font-size;
        font-weight: bold;
      }
    }
  }

  .blv-join-us-cta {
    color: $secondary-font-color;

    &:hover {
      color: darken($secondary-font-color, 10);
    }
  }
}

@include media-breakpoint-up(lg) {
  .blv-join-us-section {
    height: 550px;

    .blv-join-us-text-wrapper {
      padding: $xtra-large-padding $xtra-large-padding 0 15vw;
      transform: none;
    }

    & + section {
      padding-top: unset;
    }
  }
}
