$xtra-xtra-small-padding: 8px;
$xtra-small-padding: 12px;
$small-padding: 16px;
$medium-padding: 24px;
$large-padding: 32px;
$xtra-large-padding: 40px;
$xtra-xtra-large-padding: 60px;

$xtra-xtra-small-font-size: 14px;
$xtra-small-font-size: 16px;
$small-font-size: 18px;
$medium-font-size: 20px;
$large-font-size: 26px;
$xtra-large-font-size: 35px;
$xtra-xtra-large-font-size: 48px;

$big-breakpoint-container-max-width: 980px;
