html {
  scroll-behavior: smooth;

  body {
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 400;
    font-style: normal;
  }
}

.beliving-corporate {
  overflow: hidden;

  .blv-btn {
    background-color: transparent;
    border-color: transparent;
    border-style: solid;
    border-radius: 4px;
    padding: $xtra-xtra-small-padding;

    &:focus {
      outline: 0;
    }
  }

  .bl-cta {
    color: inherit;
    text-decoration: none;
  }

  .blv-beliving-logo-black {
    color: black;
  }
  .blv-beliving-logo-white {
    color: white;
  }
}

i.icon {
  font-size: large;
}

h1, h2, h3, h4, p, span {

}
