.blv-brand-quicklinks {
  background-color: $primary-color !important;
  padding: $medium-padding;

  .blv-quicklinks-intro {
    width: 100%;
    max-width: 600px;
    margin: $large-padding auto;
    text-align: center;

    h2 {
      font-weight: bold;
    }
  }

  .blv-quicklinks-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: $big-breakpoint-container-max-width;
    margin: 0 auto;
  }

  .blv-quicklink {
    box-shadow: 0 4px 30px -10px #{'rgb(0 0 0 / 14%)'};
    margin: $xtra-xtra-small-padding;
    background-color: white;
    width: 310px;
    padding: $medium-padding;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .blv-brand-logo {
      height: 32px;
      width: 100%;
      margin-bottom: $medium-padding;
    }

    p {
      color: #8C8E95;
    }

    .blv-btn {
      width: 100%;
      text-align: center;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .blv-overstock-garden-cta {
    border-color: $overstock-garden-bg;
    background-color: $overstock-garden-bg;
    color: $overstock-garden-color;

    &:hover {
      color: darken($overstock-garden-color, 10);
    }
  }

  .blv-exterioo-tuinmeubelen-cta {
    border-color: $exterioo-tuinmeubelen-bg;
    background-color: $exterioo-tuinmeubelen-bg;
    color: $exterioo-tuinmeubelen-color;

    &:hover {
      color: darken($exterioo-tuinmeubelen-color, 10);
    }
  }

  .blv-juntoo-cta {
    border-color: $overstock-home-bg;
    background-color: $overstock-home-bg;
    color: $overstock-home-color;

    &:hover {
      color: darken($overstock-home-color, 10);
    }
  }
}

@include media-breakpoint-up(sm) {
  .blv-brand-quicklinks {
    .blv-quicklink {
      padding: $large-padding;
    }

    padding-bottom: $xtra-xtra-large-padding;
  }
}
