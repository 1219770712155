.blv-horizontal-menu {
  width: 100%;
  z-index: 1;
  top: 0;
  transition: background-color 0.4s;
  background-color: transparent;
  position: fixed;

  &:not(.blv-menu-is-transparant) {
    background-color: darken($secondary-color, 5);

    .blv-navigation-tabs-wrapper {
      padding: $xtra-small-padding;
    }
  }

  .blv-navigation-tabs-wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: $big-breakpoint-container-max-width;
    display: flex;
    padding: $medium-padding;
    align-items: center;
    justify-content: space-between;
  }

  .blv-navigation-tabs {
    margin-bottom: 0;

    li {
      display: inline;
    }

    .blv-navigation-tab {
      &.blv-is-active, &:hover {
        color: $secondary-font-color;
      }
      &:hover {
        cursor: pointer;
        text-decoration: unset;
      }

      color: black;
    }
  }

  i {
    cursor: pointer
  }
}

.blv-mobile-side-navigation {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  transition: left 0.4s;
  z-index: 2;
  overflow: hidden;
  padding: $medium-padding;

  .blv-sidemenu-top-bar {
    display: flex;
    justify-content: space-between;
  }

  .blv-navigation-tabs {
    margin-top: $large-padding;
  }

  .blv-navigation-tab {
    color: white;

    padding-left: 0;
    font-size: $large-font-size;

    &.blv-is-active {
      color: $secondary-color;
    }
  }

  &.blv-side-navigation-is-collapsed {
    left: 0;
  }

  background-color: black;
  color: white;
}

.blv-navigation-tabs {
  list-style: none;
  padding-left: 0;

  .blv-navigation-tab{
    padding: 7px;
  }
  .blv-linked-in-nav-tab {
    padding: 0;
  }

  i.blv-linked-in-logo {
    font-size: x-large;
  }
}

.blv-beliving-logo {
  height: $xtra-large-font-size;
  width: 130px;
}


@include media-breakpoint-up(lg) {
  .blv-horizontal-menu {
    .blv-navigation-tab {

      margin: 0 $xtra-small-padding;
    }
  }
}
